import React, { useEffect } from "react";
import style from "./About.module.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import mascotme from "../../assets/images/mascot3.png";
import mascot from "../../assets/images/mascot.png";
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "G-C9QV2RVT52",
};
TagManager.initialize(tagManagerArgs);

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  useEffect(() => {
    const lenis = new Lenis({
      lerp: 0,
      smooth: true,
    });
    lenis.on("scroll", (e) => {
      console.log(e);
    });

    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);

    return () => {
      lenis.destroy();
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>about meee</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className={style.home}>
        <div className={style.home__top}>
          <div className={style.home__top__fixed}>
            <div className={style.home__top__fixed__bio}>
              <div className={style.home__top__fixed__bio__col}>
                <div className={style.home__top__fixed__bio__col__name}>
                  <div className={style.home__top__fixed__bio__col__name__rec}></div>
                  <Link to="/">
                    <h1>Ramshal Hussein</h1>
                  </Link>
                </div>
                <p>A Multidisciplinary designer focused on digital design, web development, and motion design.</p>
              </div>
            </div>
            <div className={style.home__top__fixed__job}>
              <div className={style.home__top__fixed__job__col}>
                <p>My work combines a passion for design, motion, and development for creating timeless, adaptable solutions. I always push creative boundaries by embracing modular design and experimentation.</p>
              </div>
            </div>
          </div>
          <div className={style.home__top__emot}>
            <div className={style.home__top__emot__emoji}>
              {" "}
              <h1>(*＾▽＾)／</h1>
            </div>
          </div>
        </div>
        <div className={style.home__info}>
          <div className={style.home__info__img}>
            <img src={mascotme} alt="" />
          </div>
          <div className={style.home__info__img1}>
            <img src={mascot} alt="" />
          </div>
          <div className={style.home__info__exp}>
            <div className={style.home__info__exp__col}>
              <p>Experienced Graphic Designer and Developer currently leading the creative team as Founder of Invious Visuals. Currently a student majoring in Computer Engineering. Based in Cirebon. Originally and born in Bandung, West Java.</p>
            </div>
          </div>
          <div className={style.home__info__free}>
            <div className={style.home__info__free__col}>
              <p>
                In my spare time, I pursue a balance of physical activity, exploration, and social connection. I enjoy playing badminton, discovering new cities, and the occasional outdoor adventure. Chilling with friends, family and loved ones. For
                creative fulfillment, I engage in passion projects across various disciplines such as art, game development, machine learning, and film photography.
              </p>
            </div>
          </div>
          <div className={style.home__info__free}>
            <div className={style.home__info__free__col}>
              <p>
                I'm a multidisciplinary designer with a strong foundation in graphic design (6+ years), complemented by my expanding expertise in software development. I'm proficient in industry-standard design tools like Photoshop, Illustrator,
                After Effects, Premiere Pro, DaVinci Resolve, and Figma.{" "}
              </p>

              <p>
                Additionally, I have experience in 3D rendering with Cinema 4D and Blender, as well as game development using Godot Engine. My web development toolkit includes React.js, CSS, HTML, and SCSS, and I'm actively exploring Unreal Engine,
                Maya 3D, Laravel, and Three.js.
              </p>
            </div>
          </div>
          <div className={style.home__info__emoji}>
            <h1>(〃∀〃)ゞ</h1>
          </div>
          <div className={style.home__info__footer}>
            <p>
              If you have any general questions or want to connect, please feel free to reach out. <a href="https://linkedin.com/in/ramshal">LinkedIn</a>, <a href="https://www.instagram.com/shalnv7/">Instagram</a>, ramshal@invious.studio or contact
              our studio hello@invious.studio
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
