import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import Loader from "./components/Loader";
import "./App.css";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import NotFound from "./pages/Not Found/NotFound";

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      <AnimatePresence>
        {loading ? (
          <Loader />
        ) : (
          <section>
            <Routes key={location.pathname} location={location}>
              <Route
                index
                path="/"
                element={
                  <>
                    <Home />
                  </>
                }
              />
              <Route
                path="/info"
                element={
                  <>
                    <About />
                  </>
                }
              />
              <Route
                index
                path="*"
                element={
                  <>
                    <NotFound />
                  </>
                }
              />
            </Routes>
          </section>
        )}
      </AnimatePresence>
    </>
  );
}

export default App;
