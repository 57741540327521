import React from "react";
import { m, LazyMotion, domAnimation} from "framer-motion";


const AnimatedTextWord = ({ text }) => {
  const words = text.split(" ");

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.1 * i },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { ease: [0.000, 0.835, 0.080, 1.005], duration: 1 }
    },
    hidden: {
      opacity: 1,
      y: "300%",
      transition: { ease: [0.000, 0.835, 0.080, 1.005], duration: 1 }
    },
  };



  return (
    <LazyMotion features={domAnimation}>
      <m.div
        style={{ overflow: "hidden" }}
        variants={container}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        >
        {words.map((word, index) => (
          <m.span
            variants={child}
            style= {{ marginRight: "0.5em", display: "inline-block", whiteSpace: ""}}
            key={index}
          >
            {word}
          </m.span>
        ))}
      </m.div>
    </LazyMotion>

  );
};

export default AnimatedTextWord;