import React, {useEffect} from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import lottie from "lottie-web";
import animation from "./data.json"
import "./images/img_0.png"


const Container = styled(motion.div)`
touch-action: none;
overflow: hidden;
width: 100vw;
height: 100vh;
z-index: 200;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const Text = styled(motion.span)`
  width: 30%;
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
  z-index: 5;
`;


const Loader = () => {
  let animationContainer = React.createRef()

  useEffect(() => {
    const anime = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animation,    
    })
    anime.setSpeed(1)
  },);
  
  return (
    <Container
    >
      <Text ref={animationContainer}>
        
      </Text>
    </Container>
  );
};

export default Loader;
