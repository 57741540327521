import React, { useState, useEffect } from "react";
import style from "./NotFound.module.scss";
import { LazyMotion, domAnimation } from "framer-motion";
import gif1 from "./assets/dishes.gif";
import gif2 from "./assets/maru.gif";
import gif3 from "./assets/little.gif";

import Animep from "../../components/animespantext.js";

const NotFound = () => {
  const [currentGif, setCurrentGif] = useState(gif1);
  const [currentText, setCurrentText] = useState("404");

  useEffect(() => {
    const gifs = [gif1, gif2,gif3];
    const texts = ["Don’t Worry, I’ll Get The Dishes", "Car Not Found","Me When uhh"];
    const randomIndex = Math.floor(Math.random() * gifs.length);

    setCurrentGif(gifs[randomIndex]);
    setCurrentText(texts[randomIndex]);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <LazyMotion features={domAnimation}>
      <section className={style.notFound}>
        <div className={style.notfound__gif}>
          <div className={style.notfound__gif__content}>
            <img src={currentGif} alt="" />
          </div>
        </div>
        <div className={style.notfound__content}>
          <div className={style.notfound__content__descript}>
            <h1>
              <Animep text={currentText} />
            </h1>
            <p>
              <Animep text="404" />
            </p>
          </div>
        </div>
      </section>
    </LazyMotion>
  );
};

export default NotFound;
