import React, { useEffect } from "react";
import style from "./Home.module.scss";

import { m, LazyMotion, domAnimation } from "framer-motion";
import site1 from "../../assets/images/image1.webp";
import site2 from "../../assets/images/image3.webp";
import site3 from "../../assets/images/video1.webm";
import site4 from "../../assets/images/video2.webm";
import site5 from "../../assets/images/image8.webp";
import site6 from "../../assets/images/image9.webp";
import site7 from "../../assets/images/image5.webp";
import site8 from "../../assets/images/image4.webp";
import site10 from "../../assets/images/image7.webp";
import site11 from "../../assets/images/image10.webp";
import site12 from "../../assets/images/image16.webp";
import site14 from "../../assets/images/image18.webp";
import site13 from "../../assets/images/image15.webp";
import mascot from "../../assets/images/mascot.png";
import mascotme from "../../assets/images/mascot2.png";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "G-C9QV2RVT52",
};

TagManager.initialize(tagManagerArgs);
gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  useEffect(() => {
    const lenis = new Lenis({
      lerp: 0,
      smooth: true,
    });
    lenis.on("scroll", (e) => {
      console.log(e);
    });

    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);

    return () => {
      lenis.destroy();
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ramshal Hussein</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className={style.home}>
        <div className={style.home__wrap}>
          <div className={style.home__wrap__fixed}>
            <div className={style.home__wrap__fixed__bio}>
              <div className={style.home__wrap__fixed__bio__col}>
                <div className={style.home__wrap__fixed__bio__col__name}>
                  <div className={style.home__wrap__fixed__bio__col__name__rec}></div>
                  <Link to="/info">
                    <h1>Ramshal Hussein</h1>
                  </Link>
                </div>
                <p>A Multidisciplinary designer focused on digital design, web development, and motion design.</p>
              </div>
            </div>
            <div className={style.home__wrap__fixed__job}>
              <div className={style.home__wrap__fixed__job__col}>
                <p>My work combines a passion for design, motion, and development for creating timeless, adaptable solutions. I always push creative boundaries by embracing modular design and experimentation.</p>
              </div>
            </div>
          </div>
          <div className={style.home__wrap__emot}>
            <div className={style.home__wrap__emot__emoji}>
              <h1>ヾ(＾∇＾)</h1>
            </div>
          </div>
        </div>

        <div className={style.home__line} />
        <div className={style.home__work}>
          <div className={style.home__work__line} />
          <div className={style.home__work__col}>
            <div className={style.home__work__line} />
            <div className={style.home__work__col__frame}>
              <img src={site1} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span>Marowak Pokemon</span>
                <p>Digital Design</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <img src={site14} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span>INVS Studio</span>
                <p>Web Development</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <img src={site12} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span>Ruangangan Creative</span>
                <p>Web Development</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <img src={site6} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span>Idulfitri 1444H</span>
                <p>3D - Digital Design</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <img src={site7} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span> Flow Dandelion</span>
                <p>3D</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <img src={site8} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span> INVS Recruitment</span>
                <p>3D</p>
              </div>
            </div>
          </div>
          <div className={style.home__work__line} />
          <div className={style.home__work__col}>
            <div className={style.home__work__col__space} />
            <div className={style.home__work__col__frame}>
              <video loading="lazy" type="video/webm" loop autoPlay muted src={site3}></video>
              <div className={style.home__work__col__frame__text}>
                <span> Digital di Era Teknologi 4.0</span>
                <p>Illustration / Motion Graphic</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <img src={site13} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span>Toko Ijo</span>
                <p>Web Development</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <img src={site5} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span> Lucia Realms Characters</span>
                <p>3D</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <video loading="lazy" type="video/webm" loop autoPlay muted src={site4}></video>
              <div className={style.home__work__col__frame__text}>
                <span> INVS Opening</span>
                <p>3D - Motion Design</p>
              </div>
            </div>
            <div className={style.home__work__col__frame}>
              <img src={site11} alt=""></img>
              <div className={style.home__work__col__frame__text}>
                <span> #INVSPixel</span>
                <p>Pixel Art</p>
              </div>
            </div>
          </div>
          <div className={style.home__work__line} />
        </div>
        <div className={style.home__linebot} />
        <div className={style.home__emoji}>
          <h1>♪～(￣、￣ )</h1>
        </div>
        <div className={style.home__footer}>
          <p>
            If you have any general questions or want to connect, please feel free to reach out. <a href="https://linkedin.com/in/ramshal">LinkedIn</a>, <a href="https://www.instagram.com/shalnv7/">Instagram</a>, ramshal@invious.studio or contact our
            studio hello@invious.studio
          </p>
          <div className={style.home__footer__img}>
            <img src={mascot} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
